import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios"



const Login = () => {
  const [userName,setUserName]=useState('')
  const [userPassword,setUserPassword]=useState('password')

  const [userNamev,setUserNamev]=useState()
  const [userPasswordv,setUserPasswordv]=useState()
  const [formError,setformError]=useState()

  const navigate = useNavigate();
  const loginFormSubmit=(e)=>{
     e.preventDefault();


    
     // form validation

     if (userName.length ===0 && userPassword.length === 0) {
      setUserNamev(true)
      setUserPasswordv(true)
      setformError(true)
     
      
    }
    if (userName.length > 0 && userPassword.length === 0) {
      setUserNamev(false)
      setUserPasswordv(true)
      setformError(true)
      
    }
    if (userName.length === 0 && userPassword.length > 0) {
      setUserNamev(true)
      setUserPasswordv(false)
      setformError(true)
      
    }

    if(userName.length > 0 && userPassword.length > 0){
     setformError(false)
     setUserNamev(false)
     setUserPasswordv(false)
    }


  if(!formError && userName.length > 0 && userPassword.length >0){
   
     console.log(userName)
     console.log(userPassword)

     axios.post('https://project18.nl/projectlistapi/phpapi/api/login.php', {
      username:userName,
      password:userPassword
    })
    .then(function (response) {
      console.log(response.data.message);
      if(response.data.message === 302){
        const items={username:"mars",password:"password"}
        sessionStorage.setItem('loginInfo', JSON.stringify(items));
           navigate('/projects')
       }
       if(response.data.message === 404){
        alert("user name or password is incorrect")
       }
       if(response.data.message === 403){
        alert("server issue")
       }

    })
    .catch(function (error) {
      console.log(error);
    });
    

  }
  
     
  }

  return (
    <div>
      <form className='loginForm'>
       <div className='error'>
        </div> 
      <div className='formInput'>
      <label className='error'>{ userNamev && 'Enter Your Name'}</label>
      <input type='text' className={ userNamev ? 'loginText errorfocus':'loginText' } value={userName} placeholder='Enter Name' onChange={(e)=>setUserName(e.target.value)}></input>
      </div>
      <div className='formInput' style={{display:"none"}}>
      <label className='error'>{ userPasswordv && 'Enter Your Password'}</label>
      <input type='hidden' className={ userPasswordv ? 'loginPass errorfocus':'loginPass' } value={userPassword} placeholder='Enter Password' onChange={(e)=>setUserPassword(e.target.value)}></input>
      </div>
      <div className='formInput'>
      <label className='error'></label>
      <button type='submit' className='loginBtn' onClick={loginFormSubmit}>Submit</button>
      </div>
      </form>
      
    </div>
  )
}

export default Login