import './Main.css';
import Login from './component/Login';
import Header from './component/Header';
function App() {
  return (
    <>
     <Header/>
     <Login/>
     </>

  );
}

export default App;
