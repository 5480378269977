import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Error from './component/Error';
import ProtectedRoute from './component/ProtectedRoute';
import Projects from './component/Projects';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const routes=[
  {
    path: "/",
    element: <App/>,
    errorElement: <Error />,
    
  },
  {
    path: "/projects",
    element:<ProtectedRoute >
     <Projects></Projects>
     </ProtectedRoute>,
    errorElement: <Error />,
  }
]
const router = createBrowserRouter(routes,{
  basename: "/",
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router}  />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
