import React from 'react'

const Display = ({result,deletef}) => {
    let allitems 
  if(result.length > 0 ){
    allitems = result.sort((a, b) =>a.siteUrl > b.siteUrl ? 1 : -1,); 
  }else{
    allitems=0
  }
  return (
    
<div className='tablewrapper'>
{/*<table className="list">
 <thead>
    <tr>
        <th>Id</th>
        <th>Site Name</th>
        <th>Site Url</th>
        <th>Delete</th>
    </tr>
   
    { allitems.length> 0 && allitems.map((item,index)=>{
      
      return(
        <tr key={item.id}>
         <td>{index+1}</td> 
        <td className='display_title'>{item.siteName}</td>
        <td><a href={item.siteUrl} className='openlink' target="_blank"
        rel="noreferrer">{item.siteUrl}</a></td>
        <td><button className='delite' onClick={() =>{deletef(item.id)}}>Delete</button></td>
        </tr>
      )
      
    }) }
</thead>
<tbody>

</tbody>
</table> */}
 { allitems.length> 0 && allitems.map((item,index)=>{
    return(
<div className="container_listing" key={item.id} style={ index % 2 === 0 ?{background:"#df5b50"}:{background:"#409cb2"}}>
  <div className="context_listing"> 
  <div className='site_name'><h2>{item.siteName}</h2></div>
  <div className='site_name'>{item.siteUrl}</div>
  </div>
  <div className="button_listing">
    <div className='btnwrapper'>
    <button className='viewbtn'><a href={item.siteUrl} className='openlink' target="_blank"
        rel="noreferrer">View</a></button>
    <button className='delite' onClick={() =>{deletef(item.id)}}>Delete</button>
    </div>
  </div>
</div>
      )
      
    }) }
{ allitems.length===0 && (
      <div className='norecode'>
          <h3>
           No Recode Is Available
          </h3> 
         </div>)
         }
</div>



  )
}

export default Display