import React,{useState,useEffect} from 'react'
import { v4 as uuid } from 'uuid';
import axios from 'axios';
const FormInput = ({oncreate}) => {
const unique_id = uuid();
const pid = unique_id.slice(0,3)
const [initialValues, setInitialValues] = useState({siteName: "",siteUrl: "",id: "",});
const [error,setError]=useState({siteNamev:"",siteUrlvv:"",siteUrlv:""})
const [success,setSuccess]=useState(false)

useEffect(() => {
  setTimeout(()=>{
    setSuccess(false)
  },10000)
})

const urlPatternValidation = URL => {
        const regex = new RegExp('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)');    
        return regex.test(URL);
};
//create project item logic
const formSubmit=(e)=>{
    e.preventDefault();
    
    if(initialValues.siteName.length === 0 && initialValues.siteUrl.length === 0 ){
       setError({...error,siteNamev:true,siteUrlvv:true})
    }
      if(initialValues.siteName.length > 0 && initialValues.siteUrl.length === 0 ){
        setError({...error,siteNamev:false,siteUrlvv:true})
      }
      if(initialValues.siteName.length === 0 && initialValues.siteUrl.length > 0 ){
        setError({...error,siteNamev:true,siteUrlvv:false})
        
      }
  
    if(initialValues.siteUrl.length > 0 && !urlPatternValidation(initialValues.siteUrl)){
       setError({...error,siteUrlv:true,siteNamev:false,siteUrlvv:true})
    }
    if(initialValues.siteUrl.length > 0 && urlPatternValidation(initialValues.siteUrl)){
      setError({...error,siteUrlv:false,siteNamev:false,siteUrlvv:false})
   }
   if(initialValues.siteName.length > 0 && initialValues.siteUrl.length > 0 && urlPatternValidation(initialValues.siteUrl)){
    setInitialValues({...initialValues,id:pid})

   axios.post('https://project18.nl/projectlistapi/phpapi/api/projects.php',{sitename:initialValues.siteName,siteurl:initialValues.siteUrl,id:initialValues.id} )
  .then(function (response) {
    if(response.data.message === 403){
      alert("server issue")
     }
     if(response.data.message === 201){
      setInitialValues({siteName:"",siteUrl:"",id:""})
      setError({...error,siteNamev:false,siteUrlvv:false})
      // alert("data in inserted")
      setSuccess(true)
      oncreate()
  
     }
    if(response.data.message === 500){
      alert("bad request")
     }
  
  })
  .catch(function (error) {
    console.log(error);
  });
  
  }
}
  
//create project item logic


return (
<div className='createsitelist'>
<div className='formwrapper'>

<form>
<div>
<label className='error'>{ error.siteNamev && 'Enter Your Site Name'}</label>
<input type="text" name="sitename" className="sitename" value={initialValues.siteName} onChange={(e)=>{setInitialValues({ ...initialValues, siteName: e.target.value })}} placeholder='Site Name'></input>
</div>
<div>
<label className='error'>{ error.siteUrlvv && (error.siteUrlv ?'Enter Site Url Is Not Valide':'Enter Your Site Url')}</label>
<input type="text" name="siteurl" className="siteurl" value={initialValues.siteUrl} onChange={(e)=>{setInitialValues({ ...initialValues, siteUrl: e.target.value })} } placeholder='Site Url'></input>
</div>

<div className="form-action-buttons">
<input type="submit" value="Submit" className='formsubmit' onClick={formSubmit}></input>
</div>
{ success && <div className='successmessage'><p>site has been added</p></div>}

</form>
</div>
</div> 
  )
}

export default FormInput