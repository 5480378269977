import React from 'react'
import { Link } from "react-router-dom";


const Header = () => {
  return (
    <div className='header'>
     <Link to={'/'} className='logo_anchor'>
        <div className='logo'>
          <img src={`${process.env.PUBLIC_URL}/logo.png`}  alt='logo'/>
        </div> 
        
      </Link>
        </div>
  )
}

export default Header