import React, { useState,useEffect } from 'react'
import Header from './Header'
import Confirm from './Confirm';
import axios from 'axios';
import Display from './Display';
import FormInput from './FormInput';

const Projects = () => {

const [allItem,setAllItem]=useState([])
const [loading,setLoading]=useState(true)
const [refreshs,setRefreshs]=useState(false)

//listing project item logic
useEffect(() => {

  axios.get('https://project18.nl/projectlistapi/phpapi/api/projects.php')
  .then(function (response) {

   if( response.data.length > 0 ){
    let datar = response.data
    setAllItem(datar)
    setLoading(false)
   }else{
    setAllItem([])
    setLoading(false)
   }
  })
  .catch(function (error) {
    console.log(error+"ss");
  });
  
}, [refreshs]);

//listing project item logic

//delete modal logic

const [open, setOpen] =useState({
  show: false,
  id:null
})
  
const handleConfirm =(result ,id)=> {
  if (result) {
      setOpen({...open,show:false})
      axios.delete(`https://project18.nl/projectlistapi/phpapi/api/projects.php?id=${id}` )
      .then(function (response) {
        if(response.data.message === 403){
          alert("server issue")
         }
         if(response.data.message === 200){
          if(allItem.length > 0){
            // let latestitems = allItem.filter(item => item.id !== id)
            // setAllItem(latestitems)
            setRefreshs(!refreshs)
          }
         }
        if(response.data.message === 404){
          alert("issue in delete")
         }
      
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  setOpen({...open,show:false})
}

const deletef=(id)=>{
  setOpen({...open,show:true,id: id})
}
//delete modal logic

//on create update listing
const oncreate=()=>{
  setRefreshs(!refreshs)
}

return (
  <>
  <Header/>
  <Confirm text={'are you sure?'} open={open.show} id={open.id} handleConfirm={handleConfirm}/>

<div className='projectdeatils'>
<FormInput oncreate={oncreate}/>
{loading ?(<div className='tablewrapper loaderdiv'>
  <img src={`${process.env.PUBLIC_URL}/preloader.gif`}  alt="loader"/>
</div>):<Display  result={allItem} deletef={deletef}/>}
</div>

</>
  )
}

export default Projects